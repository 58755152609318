<template>
  <v-data-table
    :headers="headers"
    :items="malla"
    :items-per-page="5"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title> Configuración malla curricular </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark class="mb-2" v-bind="attrs" v-on="on">
              Adicionar
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      :items="getCursos"
                      item-text="nombre"
                      item-value="id"
                      label="Curso"
                      v-model="editedItem.cursoId"
                      append-outer-icon="mdi-plus-outline"
                      @click:append-outer="dialogCurso = true"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      :items="getAsignaturas"
                      item-text="nombre"
                      item-value="id"
                      label="Asignatura"
                      v-model="editedItem.asignaturaId"
                      append-outer-icon="mdi-plus-outline"
                      @click:append-outer="dialogAsignatura = true"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.cargaHoraria"
                      label="Carga horaria"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogCurso" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Nuevo curso</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="defaultCurso.nombre"
                      label="Curso"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCurso = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveCursos">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAsignatura" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Nueva asignatura</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="defaultAsignatura.areaId"
                      :items="getAreas"
                      item-text="nombre"
                      item-value="id"
                      label="Area"
                      append-outer-icon="mdi-plus-outline"
                      @click:append-outer="dialogAreas = true"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="defaultAsignatura.nombre"
                      label="Asignatura"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogAsignatura = false"
              >
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveAsignatura">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAreas" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">Nueva area</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="defaultArea.nombre"
                      label="Area"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogAreas = false">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="saveAreas">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Actualizar </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data: () => ({
    dialogCurso: false,
    dialogAsignatura: false,
    dialogAreas: false,
    dialog: false,
    headers: [
      { text: "Curso", value: "nombreCurso" },
      { text: "Asignatura", value: "nombreAsignatura" },
      { text: "Cargar horaria", value: "cargaHoraria" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    malla: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      cargaHoraria: "",
      asignaturaId: "",
      cursoId: 0,
    },
    defaultItem: {
      id: 0,
      cargaHoraria: "",
      asignaturaId: "",
      cursoId: 0,
    },
    defaultCurso: {
      nombre: "",
    },
    defaultAsignatura: {
      nombre: "",
      areaId: 0,
    },
    defaultArea: {
      nombre: "",
    },
  }),

  computed: {
    ...mapGetters(["getCursos", "getAsignaturas", "getAreas"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions([
      "GetCursos",
      "GetAsignaturas",
      "GetMalla",
      "GetAreas",
      "RegistrarCursos",
      "RegistrarAsignaturas",
      "RegistrarAreas",
      "RegistrarMalla",
      "UpdateMalla",
      "ModificarMensaje"
    ]),
    ...mapMutations(["ADD_CURSOS", "ADD_ASIGNATURAS", "ADD_AREAS"]),
    initialize() {
      this.GetCursos();
      this.GetAreas();
      this.GetAsignaturas();
      this.GetMalla().then((x) => (this.malla = x.data));
    },
    editItem(item) {
      this.editedIndex = this.malla.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        //editar
        const index = this.editedIndex;
        const item = this.editedItem;
        this.UpdateMalla(item).then((x) => {
          Object.assign(this.malla[index], item);
           this.ModificarMensaje({texto:'Actualización Exitosa!'})
        });
      } else {
        //nuevo
        this.RegistrarMalla(this.editedItem).then((x) => {
          const respuesta = x.data;
          if (!!respuesta) {
            this.malla.push(respuesta);
            this.ModificarMensaje({texto:'Registro Exitoso!'})
          }
           
        });
      }
      this.close();
    },
    saveCursos() {
      this.RegistrarCursos(this.defaultCurso).then((x) => {
        const respuesta = x.data;
        if (!!respuesta) {
          this.ADD_CURSOS(respuesta);
           this.dialogCurso = false;
    
        }
      });
    },
    saveAsignatura() {
      this.RegistrarAsignaturas(this.defaultAsignatura).then((x) => {
        const respuesta = x.data;
        if (!!respuesta) {
          this.ADD_ASIGNATURAS(respuesta);
          this.dialogAsignatura= false;
    
        }
      });
    },
    saveAreas() {
      this.RegistrarAreas(this.defaultArea).then((x) => {
        const respuesta = x.data;
        if (!!respuesta) {
          this.ADD_AREAS(respuesta);
          this.dialogAreas = false;
        }
      });
    },
  },
};
</script>