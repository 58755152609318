<template>
    <v-container>
        <Malla></Malla>
    </v-container>
</template>
<script>
import Malla from "@/components/MallaCurricular.vue"
export default {
    components: {
        Malla
    }
}
</script>